@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --size-section--section-max-width: 1200px;
    --size-section--section-padding-block: 2rem;
    --color-base--bg-200: #fcfcfc;
  }

  body {
    @apply font-sans text-gray-800;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  h1, h2, h3, h4, h5, h6 {
    @apply font-serif;
  }
}

@layer utilities {
  .section-wrapper {
    max-width: var(--size-section--section-max-width);
    padding-top: var(--size-section--section-padding-block);
    padding-bottom: var(--size-section--section-padding-block);
    background-color: var(--color-base--bg-200);
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}


/* Modal Animation */
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 40;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  width: 100%;
  max-height: calc(100vh - 64px); /* Angepasst, um die Navbar-Höhe zu berücksichtigen */
  margin-top: 64px; /* Entspricht der Navbar-Höhe */
  transform: translateY(-100%);
  transition: transform 300ms ease-in;
}

.modal-enter-active .modal-content,
.modal-enter-done .modal-content {
  transform: translateY(0);
}

.modal-exit-active .modal-content {
  transform: translateY(-100%);
}

/* Burger Menu Animation */
.burger-line {
  width: 100%;
  height: 2px;
  background-color: white;
  transition: all 0.3s ease;
}

.burger-line:not(:last-child) {
  margin-bottom: 5px;
}

.open .burger-line:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.open .burger-line:nth-child(2) {
  opacity: 0;
}

.open .burger-line:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

@media (min-width: 1920px) {
  :root {
    --size-section--section-max-width: 1824px; /* 95% of 1920px */
  }
}


/* Modal-Anpassungen */
.modal-container {
  z-index: 50; /* Sicherstellen, dass das Modal über anderen Elementen liegt */
}

.menu-enter {
  transform: translateY(-100%);
}
.menu-enter-active {
  transform: translateY(0);
  transition: transform 300ms;
}
.menu-exit {
  transform: translateY(0);
}
.menu-exit-active {
  transform: translateY(-100%);
  transition: transform 300ms;
}


.gallery-section {
  .masonry-container {
    width: 100%;
    margin: auto;
    margin-top: 10px;
  }

  .masonry {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: stretch;
    width: 100%;
    margin: auto;
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: stretch;
    flex-grow: 1;
  }

  .tile {
    margin: 4px;
    cursor: pointer;

    img {
      box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      width: 100%;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.04);
      }
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    img {
      max-width: 90%;
      max-height: 90%;
      object-fit: contain;
    }
  }
}